var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "w": "full"
    }
  }, [_c('Breadcrumbs', {
    attrs: {
      "items": _vm.breadcrumbItems
    }
  }), _c('hr', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "w": "full"
    }
  }), _c('c-box', {
    attrs: {
      "mt": "30px"
    }
  }, [_c('c-text', {
    attrs: {
      "color": "primary.400",
      "text-transform": "uppercase"
    }
  }, [_vm._v(" Detail Program ")]), _vm.program ? _c('c-text', {
    attrs: {
      "font-size": "24px",
      "font-weight": "700"
    }
  }, [_vm._v(" " + _vm._s(_vm.program.name) + " ")]) : _vm._e()], 1), _vm.nutritionist ? _c('c-box', {
    attrs: {
      "mt": "30px"
    }
  }, [_c('c-text', [_vm._v("Ahli Gizi Yang Dipilih")]), _c('hr'), _c('c-flex', {
    attrs: {
      "mt": "20px",
      "p": "20px",
      "border": "1px solid #F2F2F2",
      "box-shadow": "0px 5px 30px 0px #0000000D",
      "rounded": "md"
    }
  }, [_vm.nutritionist ? _c('c-image', {
    attrs: {
      "src": _vm.nutritionist.photoUrl,
      "object-fit": "cover",
      "rounded": "full",
      "h": "96px",
      "w": "96px"
    }
  }) : _vm._e(), _c('c-box', {
    attrs: {
      "ml": "20px"
    }
  }, [_c('c-stack', {
    attrs: {
      "is-inline": "",
      "spacing": "20px"
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": "18px",
      "font-weight": "700"
    }
  }, [_vm._v(" " + _vm._s(_vm.nutritionist.firstName) + " " + _vm._s(_vm.nutritionist.lastName) + " " + _vm._s(_vm.nutritionist.title) + " ")]), _vm.isAvailable ? _c('c-badge', {
    attrs: {
      "rounded": "full",
      "color": "primary.400",
      "variant-color": "primary",
      "variant": "outline",
      "border": "1px solid",
      "d": "flex",
      "align-items": "center",
      "justify-content": "center",
      "px": "15px",
      "text-transform": "none",
      "font-size": "12px",
      "font-weight": "500"
    }
  }, [_vm._v(" Available ")]) : _vm._e(), !_vm.isAvailable ? _c('c-badge', {
    attrs: {
      "rounded": "full",
      "d": "flex",
      "align-items": "center",
      "justify-content": "center",
      "bg": "#EA43351A",
      "border": "1px solid #EA4335",
      "w": "fit-content",
      "px": "15px",
      "variant": "outline",
      "variant-color": "primary",
      "color": "#EA4335",
      "text-transform": "none",
      "font-size": "12px",
      "font-weight": "500"
    }
  }, [_vm._v(" Tidak Aktif ")]) : _vm._e()], 1), _c('c-text', {
    attrs: {
      "font-size": "16px",
      "font-weight": "400",
      "color": "primary.400"
    }
  }, [_vm._v(" Nama Spesialiasi ")]), _c('c-text', {
    attrs: {
      "font-size": "14px",
      "font-weight": "400",
      "line-height": "21px",
      "color": "grey"
    }
  }, [_vm._v(" S1 & Sekolah Profesi Universitas Brawijaya ")]), _c('c-button', {
    attrs: {
      "mt": "20px",
      "bg": "#F4CC46",
      "color": "#927102",
      "rounded": "full",
      "as": _vm.isAbleToChangeNutri ? 'router-link' : 'button',
      "to": {
        name: 'superadmin.clients-nutris'
      },
      "is-disabled": !_vm.isAbleToChangeNutri,
      "disabled": !_vm.isAbleToChangeNutri
    }
  }, [_c('c-text', [_vm._v("Ganti Ahli Gizi")]), _c('c-image', {
    attrs: {
      "src": require('@/assets/icon-arrow-right-yellow.svg'),
      "h": "24px",
      "w": "24px",
      "ml": "5px"
    }
  })], 1)], 1), _c('c-text', {
    attrs: {
      "ml": "auto",
      "font-size": "16px",
      "color": "gray.900"
    }
  }, [_c('c-image', {
    attrs: {
      "src": require('@/assets/icon-star.svg'),
      "alt": "icon",
      "display": "inline-block",
      "vertical-align": "middle",
      "margin-right": "0px"
    }
  }), _vm._v(" " + _vm._s(_vm.nutritionist.rating) + "/5 ")], 1)], 1)], 1) : _vm._e(), _c('c-flex', {
    attrs: {
      "mt": "30px",
      "grid-gap": "15px",
      "flex-wrap": "wrap"
    }
  }, [_c('c-button', {
    attrs: {
      "type": "button",
      "h": "94px",
      "w": "439px",
      "bg": "white",
      "border": "1px solid #F2F2F2",
      "box-shadow": "0px 5px 30px 0px #0000000D",
      "rounded": "md",
      "justify-content": "flex-start",
      "grid-gap": "20px",
      "px": "35px",
      "as": "router-link",
      "to": {
        name: 'superadmin.clients-mealplan'
      }
    }
  }, [_c('c-image', {
    attrs: {
      "src": require('@/assets/icon-menu-meat.svg'),
      "size": "32px"
    }
  }), _c('c-text', [_vm._v("Meal Plan")])], 1), _c('c-button', {
    attrs: {
      "type": "button",
      "h": "94px",
      "w": "439px",
      "bg": "white",
      "border": "1px solid #F2F2F2",
      "box-shadow": "0px 5px 30px 0px #0000000D",
      "rounded": "md",
      "justify-content": "flex-start",
      "grid-gap": "20px",
      "px": "35px",
      "as": "router-link",
      "to": {
        name: 'superadmin.clients-followup-notes'
      }
    }
  }, [_c('c-image', {
    attrs: {
      "src": require('@/assets/icon-menu-notes.svg'),
      "size": "32px"
    }
  }), _c('c-text', [_vm._v("Follow Up Notes")])], 1), _c('c-button', {
    attrs: {
      "type": "button",
      "h": "94px",
      "w": "439px",
      "bg": "white",
      "border": "1px solid #F2F2F2",
      "box-shadow": "0px 5px 30px 0px #0000000D",
      "rounded": "md",
      "justify-content": "flex-start",
      "grid-gap": "20px",
      "px": "35px",
      "as": "router-link",
      "to": {
        name: 'superadmin.clients-progress'
      }
    }
  }, [_c('c-image', {
    attrs: {
      "src": require('@/assets/ic_trending_active.svg'),
      "size": "32px"
    }
  }), _c('c-text', [_vm._v("Progress and Tracker")])], 1), _c('c-button', {
    attrs: {
      "type": "button",
      "h": "94px",
      "w": "439px",
      "bg": "white",
      "border": "1px solid #F2F2F2",
      "box-shadow": "0px 5px 30px 0px #0000000D",
      "rounded": "md",
      "justify-content": "flex-start",
      "grid-gap": "20px",
      "px": "35px",
      "as": "router-link",
      "to": {
        name: 'superadmin.clients-quizionary'
      }
    }
  }, [_c('c-image', {
    attrs: {
      "src": require('@/assets/ic_question-mark_active.svg'),
      "size": "32px"
    }
  }), _c('c-text', [_vm._v("Kuisioner")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }